<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--user"></div>
      <div class="modal__back" @click="goBack()"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div
            class="navigator"
            @click="currentPage > 0 ? goTo(0) : null"
            :class="{
              'navigator--active':
                currentPage === 0 ||
                currentPage === 1 ||
                currentPage === 2 ||
                currentPage === 3,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addUserModal.${"details"}`) }}
            </div>
          </div>
          <div
            class="navigator"
            @click="goTo(1)"
            :class="{
              'navigator--active':
                currentPage === 1 || currentPage === 2 || currentPage === 3,
            }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addUserModal.${"contact"}`) }}
            </div>
          </div>
          <div
            class="navigator"
            @click="goTo(2)"
            :class="{
              'navigator--active': currentPage === 2 || currentPage === 3,
            }"
          >

              <div class="navigator__bullet"></div>
              <div class="navigator__title">
                {{ $t(`addUserModal.${"rights"}`) }}
              </div>


          </div>
          <div
            class="navigator"
            @click="goTo(3)"
            :class="{ 'navigator--active': currentPage === 3 }"
          >
            <div class="navigator__bullet"></div>
            <div class="navigator__title">
              {{ $t(`addUserModal.${"image"}`) }}
            </div>
          </div>
        </div>

        <form class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading" />
            <div v-else class="add__content">
              <transition name="fade" mode="out-in">
                <div class="details" key="0" v-if="currentPage === 0">
                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <VSelect
                        :options="genders"
                        :reduce="(gender) => gender.id"
                        placeholder="Anrede *"
                        label="name"
                        v-model="userDetails.gender"
                        class="select select--default"
                        :class="{ 'select--error': genderHasError }"
                      />
                      <div class="form__error" v-if="genderHasError">
                        Please select a gender
                      </div>
                    </div>

                    <div class="form__field form__field--double">
                      <VSelect
                        :options="titles"
                        :reduce="(title) => title.id"
                        placeholder="Titel"
                        label="name"
                        v-model="userDetails.titleId"
                        class="select select--default"
                      />
                    </div>
                  </div>

                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Vorname *"
                      v-model="userDetails.firstName"
                      :class="{
                        'input--error': firstNameHasError,
                      }"
                    />
                    <div class="form__error" v-if="firstNameHasError">
                      {{ firstNameErrorPlaceholder }}
                    </div>
                  </div>

                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Name *"
                      v-model="userDetails.lastName"
                      :class="{ 'input--error': lastNameHasError }"
                    />
                    <div class="form__error" v-if="lastNameHasError">
                      {{ lastNameErrorPlaceholder }}
                    </div>
                  </div>

                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <Datepicker
                        class="input input--default"
                        :class="{
                          'input--error': birthdayHasError,
                        }"
                        :config="statics.datePickerConfig"
                        placeholder="Geburtstag *"
                        v-model="userDetails.birthday"
                      />
                      <div class="form__error" v-if="birthdayHasError">
                        Please enter your birthdate
                      </div>
                    </div>
                    <div class="form__field form__field--double">
                      <VSelect
                        :options="languages"
                        :reduce="(language) => language.id"
                        placeholder="Sprache *"
                        label="code"
                        v-model="userDetails.language"
                        class="select select--default"
                        :class="{ 'select--error': languageHasError }"
                      />
                      <div class="form__error" v-if="languageHasError">
                        Please select a language
                      </div>
                    </div>
                  </div>

                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <input
                        type="text"
                        class="input input--default"
                        placeholder="Strasse"
                        v-model="userAddress[0].street"
                      />
                    </div>
                    <div
                      class="form__field form__field--double form__field--nr"
                    >
                      <input
                        type="text"
                        class="input input--default"
                        placeholder="Nr."
                        v-model="userAddress[0].number"
                      />
                    </div>
                  </div>

                  <div class="form__row">
                    <div class="form__field form__field--double">
                      <input
                        type="text"
                        class="input input--default"
                        placeholder="PLZ *"
                        v-model="userAddress[0].zip"
                        :class="{ 'input--error': zipHasError }"
                      />

                      <div class="form__error" v-if="zipHasError">
                        {{ zipCodePlaceholder }}
                      </div>
                    </div>
                    <div class="form__field form__field--double">
                      <VSelect
                        v-if="zipCodeData.length > 1"
                        :options="zipCodeData"
                        :reduce="(city) => city.city"
                        placeholder="ORT *"
                        label="city"
                        v-model="userAddress[0].city"
                        class="select select--default"
                        :class="{
                          'select--error': cityHasError,
                        }"
                      />

                      <div
                        v-else-if="zipCodeData.length"
                        class="input input--default input--readonly"
                        :class="{ 'input--error': cityHasError }"
                      >
                        {{ userAddress[0].city }}
                      </div>

                      <input
                        v-else-if="!zipCodeData.length"
                        type="text"
                        class="input input--default"
                        placeholder="ORT *"
                        v-model="userAddress[0].city"
                        :class="{ 'input--error': cityHasError }"
                      />

                      <div class="form__error" v-if="cityHasError">
                        City should be filled
                      </div>
                    </div>
                  </div>
                </div>

                <div class="contact" key="1" v-if="currentPage === 1">
                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="E-mail *"
                      v-model="userContact.email"
                      :class="{ 'input--error': emailHasError }"
                    />
                    <div class="form__error" v-if="emailHasError">
                      {{ emailPlaceholder }}
                    </div>
                  </div>

                  <div class="form__field form__field--prefix">
                    <div class="form__row">
<!--                      <div
                        class="form__input-wrapper form__input-wrapper&#45;&#45;prefix"
                      >
                        <VSelect
                          :options="phonePrefixes"
                          class="select select&#45;&#45;default"
                          :class="{ 'input&#45;&#45;error': phonePrefixHasError }"
                          placeholder="Prefix"
                          v-model="userContact.phonePrefix"
                        />
                        <div class="form__error" v-if="phonePrefixHasError">
                          {{ phonePrefixErrorPlaceholder }}
                        </div>
                      </div>-->
                      <div class="form__input-wrapper">
                        <input
                          type="text"
                          class="input input--default"
                          placeholder="Telefon *"
                          v-model="userContact.phone"
                          :class="{ 'input--error': phoneHasError }"
                        />
                        <div class="form__error" v-if="phoneHasError">
                          {{ phoneErrorPlaceholder }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form__field">
                    <VSelect
                      :options="countries"
                      :reduce="(country) => country.code"
                      placeholder="Nationalitat"
                      label="name_en"
                      v-model="userContact.countryCode"
                      class="select select--default"
                    />
                  </div>

                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="AHV Nr."
                      v-model="userContact.ahvNr"
                    />
                  </div>

                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Bankname"
                      v-model="userBanks[0].name"
                    />
                  </div>

                  <div class="form__field">
                    <input
                      type="text"
                      class="input input--default"
                      placeholder="Account"
                      v-model="userBanks[0].account"
                    />
                  </div>
                </div>

                <div class="rights" key="2" v-if="currentPage === 2 ">
                  <template v-if="!this.isProfileEdit">
                    <div class="form__field">
                      <VSelect
                        :options="users"
                        :reduce="(user) => user.id"
                        placeholder="Supervisor"
                        :getOptionLabel="
                          (user) => `${user.first_name} ${user.last_name}`
                        "
                        v-model="userRights.supervisorId"
                        class="select select--default"
                      />
                    </div>

                    <div class="form__field">
                      <VSelect
                        :options="roles"
                        :reduce="(role) => role.id"
                        placeholder="Userrecht *"
                        label="title"
                        @input="selectRoles($event)"
                        class="select select--default"
                        :class="{
                          'select--error': rolesHasError,
                        }"
                      />
                      <div class="form__error" v-if="rolesHasError">
                        Please select a role
                      </div>

                      <div class="tabs" v-if="userRights.roles.length">
                        <div
                          class="tab"
                          v-for="role in userRights.roles"
                          :key="role"
                        >
                          {{ roles.find((r) => r.id === role).title }}
                          <div
                            class="tab__delete"
                            @click="removeRoleTab(role)"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div class="form__field">
                      <VSelect
                        :options="saleCategories"
                        :reduce="(sale) => sale.id"
                        placeholder="Abrechnungsgruppe *"
                        label="name"
                        v-model="userRights.salesCategoryId"
                        class="select select--default"
                        :class="{
                          'select--error': salesHasError,
                        }"
                      />
                      <div class="form__error" v-if="salesHasError">
                        Please select a sale category
                      </div>
                    </div>

                    <div class="form__field" v-if="$checkRolePermission('team_access')">
                      <VSelect
                        :options="teams"
                        :reduce="(team) => team.id"
                        placeholder="Teams *"
                        label="name"
                        v-model="userRights.teamId"
                        class="select select--default"
                        :class="{
                          'select--error': teamHasError,
                        }"
                      />
                      <div class="form__error" v-if="teamHasError">
                        Please select a team
                      </div>
                    </div>
                    <div class="form__field">
                      <div class="details__option">
                        <label class="details__option-name" for="ahv"
                        >AHV</label
                        >
                        <div class="checkbox">
                          <input
                              type="checkbox"
                              id="ahv"
                              v-model="userRights.hasAhv"
                              class="checkbox__checkbox"

                          />
                          <label
                              for="ahv"
                              class="checkbox__label"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div class="form__field">
                      <div class="details__option">
                        <label class="details__option-name" for="bvg"
                        >BVG</label
                        >
                        <div class="checkbox">
                          <input
                              type="checkbox"
                              id="bvg"

                              class="checkbox__checkbox"
                              v-model="userRights.hasBvg"
                          />
                          <label
                              for="bvg"
                              class="checkbox__label"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div class="form__field form__field--double">
                      <input
                          v-if="userRights.hasBvg"
                          type="text"
                          class="input input--default"
                          placeholder="BVG *"
                          v-model="userRights.bvgValue"

                      />

                      <div class="form__error" >

                      </div>
                    </div>


                  </template>

                </div>

                <div class="logo" key="3" v-if="currentPage === 3">
                  <div class="add__avatar">
                    <img
                      v-if="userImage"
                      :src="userImage"
                      alt=""
                      ref="userImage"
                    />
                    <div v-else class="add__avatar-empty">
                      <img
                        class="add__avatar-empty"
                        src="../assets/icons/default/Bold 2px-profile_circled-white.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="add__upload-text">
                    Upload your Image / Logo
                  </div>
                  <div class="add__upload-wrapper">
                    <button
                      type="button"
                      class="button button--default button--upload"
                      @click="$refs.uploadFile.click()"
                    >
                      Upload Image
                    </button>
                    <input
                      ref="uploadFile"
                      type="file"
                      class="add__upload"
                      @change="uploadImage($event)"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </form>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        type="button"
        class="modal__button modal__button--submit button button--default"
        @click="currentPage !== 3 ? goForward() : createUser()"
      >
        <span v-if="currentPage === 3">Save</span>
        <span v-else>{{ $t(`addUserModal.${"next"}`) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/partials/Loading";
import statics from "@/statics";
import parseFieldErrors from "@/helpers/parseFieldErrors";
export default {
  name: "AddUserModal",

  components: {
    Loading,
  },

  data() {
    return {
      statics,
      parseFieldErrors,
      currentPage: 0,
      editingUser: "",

      userDetails: {
        gender: "",
        titleId: "",
        firstName: "",
        lastName: "",
        birthday: "",
        language: "",
      },
      userContact: {
        email: "",
        phonePrefix: "",
        phone: "",
        countryCode: "",
        ahvNr: "",
      },
      userRights: {
        supervisorId: "",
        roles: [],
        salesCategoryId: "",
        teamId: "",
        hasAhv: 0,
        hasBvg: 0,
        bvgValue: 0,
      },
      userAddress: [
        {
          street: "",
          number: "",
          city: "",
          zip: "",
        },
      ],

      userBanks: [
        {
          name: "",
          account: "",
        },
      ],

      image: "",
      file: "",
      confirmPassword: "",
      userImage: "",

      firstNameHasError: false,
      lastNameHasError: false,
      genderHasError: false,
      birthdayHasError: false,
      languageHasError: false,
      zipHasError: false,
      cityHasError: false,
      emailHasError: false,
      phonePrefixHasError: false,
      phoneHasError: false,
      rolesHasError: false,
      salesHasError: false,
      teamHasError: false,



      zipCodePlaceholder: "",

      emailPlaceholder: "",
      firstNameErrorPlaceholder: "",
      lastNameErrorPlaceholder: "",
      phonePrefixErrorPlaceholder: "",
      phoneErrorPlaceholder: "",

      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      formLoading: false,
      isRequesting: false,

      specificErrors: [],
    };
  },

  watch: {
    "userDetails.gender"(value) {
      if (value) {
        this.genderHasError = false;
      } else {
        this.genderHasError = true;
      }
    },
    "userDetails.firstName"(value) {
      if (value) {

          this.firstNameHasError = false;
          this.firstNameErrorPlaceholder = "";

      } else {
        this.firstNameHasError = true;
        this.firstNameErrorPlaceholder = "Please enter first name";
      }
    },
    "userDetails.lastName"(value) {
      if (value) {
        this.lastNameHasError = false;
        this.lastNameErrorPlaceholder = "";
      } else {
        this.lastNameHasError = true;
        this.lastNameErrorPlaceholder = "Please enter last name";
      }
    },
    "userDetails.birthday"(value) {
      if (value) {
        this.birthdayHasError = false;
      } else {
        this.birthdayHasError = true;
      }
    },
    "userDetails.language"(value) {
      if (value) {
        this.languageHasError = false;
      } else {
        this.languageHasError = true;
      }
    },
    async zip(zip) {
      if (zip) {
        this.zipHasError = false;
        this.zipCodePlaceholder = "";

        if (zip >= 1000 && zip <= 9999) {
          await this.loadCities(zip);
        } else {
          this.zipCodeData = [];
          this.userAddress[0].city = "";
          this.zipHasError = true;
          this.zipCodePlaceholder = "Invalid zip code";
        }
      } else {
        this.userAddress[0].city = "";
        this.zipHasError = true;
        this.zipCodePlaceholder = "Please enter a zip code";
      }
    },
    city(value) {
      if (value) {
        this.cityHasError = false;
      } else {
        this.cityHasError = true;
      }
    },
    "userContact.email"(value) {
      if (value) {
        this.emailHasError = false;
        this.emailPlaceholder = "";

        if (!this.emailRegex.test(value.toLowerCase())) {
          this.emailHasError = true;
          this.emailPlaceholder = "Invalid email address";
        }
      } else {
        this.emailHasError = true;
        this.emailPlaceholder = "Email address field is required";
      }
    },
    "userContact.phonePrefix"(value) {
      if (!value) {
        this.phonePrefixHasError = true;
        this.phonePrefixErrorPlaceholder = "Invalid prefix.";
      } else {
        this.phonePrefixHasError = false;
        this.phonePrefixErrorPlaceholder = "";
      }
    },
    "userContact.phone"(value) {
      if (value) {
        if (!this.statics.phoneRegex.test(value)) {
          this.phoneHasError = true;
          this.phoneErrorPlaceholder = "Invalid phone number.";
        } else {
          this.phoneHasError = false;
          this.phoneErrorPlaceholder = "";
        }
      } else {
        this.phoneHasError = true;
        this.phoneErrorPlaceholder = "Please enter phone number";
      }
    },
    "userRights.roles"(value) {
      if (value.length) {
        this.rolesHasError = false;
      } else {
        this.rolesHasError = true;
      }
    },
    "userRights.salesCategoryId"(value) {
      if (value) {
        this.salesHasError = false;
      } else {
        this.salesHasError = true;
      }
    },
    "userRights.teamId"(value) {
      if (value) {
        this.teamHasError = false;
      } else {
        this.teamHasError = true;
      }
    },
    "userRights.password"(value) {
      if (value) {
        this.passwordHasError = false;
        this.passwordPlaceholder = "";

        if (value.length < 7) {
          this.passwordHasError = true;
          this.passwordPlaceholder =
            "Password must be a minimum of 7 characters";
        }
      } else {
        this.passwordHasError = true;
        this.passwordPlaceholder = "Password field is required";
      }
    },
    confirmPassword(value) {
      if (value) {
        this.confirmPasswordPlaceholder = "";
        this.confirmPasswordHasError = false;
        if (value !== this.userRights.password) {
          this.confirmPasswordPlaceholder = "Password does not match";
          this.confirmPasswordHasError = true;
        }
      } else {
        this.confirmPasswordPlaceholder = "Please fill this field";
        this.confirmPasswordHasError = true;
      }
    },

    specificErrors(errors) {
      const errorKeys = errors.map((error) => error.errorKey);



      if (errorKeys.includes("email")) {
        this.currentPage = 1;
        this.emailHasError = true;
        this.emailPlaceholder = errors.find(
          (error) => error.errorKey === "email"
        ).errorValue;
      } else {
        this.emailHasError = false;
        this.emailPlaceholder = "";
      }

      if (errorKeys.includes("phone")) {
        this.currentPage = 1;
        this.phoneHasError = true;
        this.phoneErrorPlaceholder = errors.find(
          (error) => error.errorKey === "phone"
        ).errorValue;
      } else {
        this.phoneHasError = false;
        this.phoneErrorPlaceholder = "";
      }

      if (errorKeys.includes("first_name")) {
        this.currentPage = 0;
        this.firstNameErrorPlaceholder = errors.find(
          (error) => error.errorKey === "first_name"
        ).errorValue;
        this.firstNameHasError = true;
      } else {
        this.firstNameHasError = false;
        this.firstNameErrorPlaceholder = "";
      }

      if (errorKeys.includes("last_name")) {
        this.currentPage = 0;
        this.lastNameErrorPlaceholder = errors.find(
          (error) => error.errorKey === "last_name"
        ).errorValue;
        this.lastNameHasError = true;
      } else {
        this.lastNameHasError = false;
        this.lastNameErrorPlaceholder = "";
      }
    },
  },

  async mounted() {
    this.formLoading = true;

    await this.$store.dispatch("getUserForms");

    if (this.isEdit || this.isProfileEdit) {
      let user;
      if(this.isProfileEdit){
         user = await this.$store.dispatch("getProfile");
      }else{
         user = await this.$store.dispatch("getUser", this.userToEdit);
      }


      this.editingUser = user;
      this.loadFieldsForEdit(user);
    }
    this.formLoading = false;
  },

  computed: {
    ...mapGetters(["userForms", "isEdit", "userToEdit", "isProfileEdit", "loggedUser"]),

    roles() {
      return this.userForms.roles;
    },

    users() {
      return this.userForms.supervisors;
    },

    teams() {
      return this.userForms.teams;
    },

    phonePrefixes() {
      return this.userForms.prefixes.map((prefix) => prefix.phone_prefix);
    },

    genders() {
      return this.userForms.genders;
    },

    titles() {
      return this.userForms.titles;
    },

    languages() {
      return this.userForms.languages;
    },

    countries() {
      return this.userForms.countries;
    },

    saleCategories() {
      return this.userForms["sales-categories"];
    },

    zipCodeData: {
      get() {
        return this.$store.state.zipCodes.zipCodeData;
      },
      set(value) {
        this.$store.commit("setZipCodeData", value);
      },
    },

    zip() {
      return this.userAddress[0].zip;
    },
    city() {
      return this.userAddress[0].city;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddUserModal", false);
      this.$store.commit("setIsEdit", false);
      this.$store.commit("setIsProfileEdit", false);
      this.zipCodeData = [];
    },
    loadFieldsForEdit(userToEdit) {
      const {
        ahv_number,
        birthday,
        country,
        email,
        first_name,
        gender,
        language,
        last_name,
        phone,
        prefix,
        photo,
        roles,
        sales_category,
        supervisor,
        team,
        title,
        user_addresses,
        user_banks,
          has_ahv,
          has_bvg,
          bvg_value
      } = userToEdit;

      this.userContact.ahvNr = ahv_number || "";
      this.userDetails.birthday = birthday || "";
      this.userContact.countryCode = country ? country.code : "";
      this.userContact.email = email || "";
      this.userDetails.firstName = first_name || "";
      this.userDetails.gender = gender ? gender.id : "";
      this.userDetails.language = language ? language.id : "";
      this.userDetails.lastName = last_name || "";
      this.userContact.phone = phone || "";
      this.userContact.phonePrefix = prefix || "";
      this.userImage = photo ? photo.thumbnail : "";
      this.userRights.roles = roles.map((role) => role.id) || "";
      this.userRights.salesCategoryId = sales_category ? sales_category.id : "";
      this.userRights.supervisorId = supervisor ? supervisor.id : "";
      this.userRights.teamId = team ? team.id : "";
      this.userRights.hasAhv = has_ahv || 0;
      this.userRights.hasBvg = has_bvg || 0;
      this.userRights.bvgValue = bvg_value || "";
      this.userDetails.titleId = title ? title.id : "";
      this.userAddress[0].id = user_addresses.length
        ? user_addresses[0].id
        : "";
      this.userAddress[0].street = user_addresses.length
        ? user_addresses[0].street
        : "";
      this.userAddress[0].number = user_addresses.length
        ? user_addresses[0].number
        : "";
      this.userAddress[0].city = user_addresses.length
        ? user_addresses[0].city
        : "";
      this.userAddress[0].zip = user_addresses.length
        ? user_addresses[0].zip
        : "";
      this.userAddress[0].city = user_addresses.length
        ? user_addresses[0].city
        : "";
      this.userBanks[0].id = user_banks.length ? user_banks[0].id : "";
      this.userBanks[0].name = user_banks.length ? user_banks[0].name : "";
      this.userBanks[0].account = user_banks.length
        ? user_banks[0].account
        : "";
    },

    goBack() {
      this.currentPage--;
      if (this.currentPage < 0) {
        this.closeModal();
        this.$store.commit("setShowAddCustomerModal", true);
      }
    },

    goTo(page) {
      if (page === 1) {
        this.validateUserDetails((state) => {
          if (state) {
            this.currentPage = page;
          }
        });
      } else if (page === 2) {
        this.validateUserDetails((detailsState) => {
          this.validateUserContact((contactState) => {
            if (contactState && detailsState) {
              this.currentPage = page;
            }
          });
        });
      } else if (page === 3) {
        this.validateUserDetails((detailsState) => {
          this.validateUserContact((contactState) => {
            this.validateUserRights((rightsState) => {
              if (contactState && detailsState && rightsState) {
                this.currentPage = page;
              }
            });
          });
        });
      } else {
        this.currentPage = page;
      }
    },

    goForward() {
      if (this.currentPage < 3) {
        if (this.currentPage === 0) {
          this.validateUserDetails((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        } else if (this.currentPage === 1) {
          this.validateUserContact((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        } else if (this.currentPage === 2) {
          this.validateUserRights((state) => {
            if (state) {
              this.currentPage++;
            }
          });
        }
      }
    },

    validateUserDetails(callback) {
      const {
        firstName,
        lastName,
        birthday,
        gender,
        language,
      } = this.userDetails;
      const { zip, city } = this.userAddress[0];
      if (!firstName) {
        this.firstNameHasError = true;
        this.firstNameErrorPlaceholder = "Please enter first name";
      } else {

          this.firstNameHasError = false;
          this.firstNameErrorPlaceholder = "";

      }

      if (!lastName) {
        this.lastNameHasError = true;
        this.lastNameErrorPlaceholder = "Please enter last name";
      } else {

          this.lastNameHasError = false;
          this.lastNameErrorPlaceholder = "";

      }

      if (!birthday) {
        this.birthdayHasError = true;
      }
      if (!gender) {
        this.genderHasError = true;
      }
      if (!language) {
        this.languageHasError = true;
      }
      if (!zip) {
        this.zipHasError = true;
        this.zipCodePlaceholder = "Please enter a zip code";
      }
      if (!city) {
        this.cityHasError = true;
      }

      if (
        !this.firstNameHasError &&
        !this.lastNameHasError &&
        !this.birthdayHasError &&
        !this.genderHasError &&
        !this.languageHasError &&
        !this.zipHasError &&
        !this.cityHasError
      ) {
        callback(true);
      }
    },

    validateUserContact(callback) {
      const { email,  phone } = this.userContact;
      if (!email) {
        this.emailHasError = true;
        this.emailPlaceholder = "Email address field is required";
      }
   /*   if (!phonePrefix) {
        this.phonePrefixHasError = true;
        this.phonePrefixErrorPlaceholder = "Invalid prefix.";
      }*/
      if (!phone) {
        this.phoneHasError = true;
        this.phoneErrorPlaceholder = "Please enter phone number";
      }
      if (
        !this.emailHasError &&
        !this.phoneHasError &&
        !this.phonePrefixHasError
      ) {
        callback(true);
      }
    },

    validateUserRights(callback) {
      const { roles, salesCategoryId, teamId } = this.userRights;

      if (!roles.length) {
        this.rolesHasError = true;
      }

      if (!salesCategoryId) {
        this.salesHasError = true;
      }

      if (!teamId && this.$checkRolePermission('team_access')) {
        this.teamHasError = true;
      }


      if (
        !this.rolesHasError &&
        !this.salesHasError &&
        !this.teamHasError

      ) {
        callback(true);
      }
    },

    removeRoleTab(roleId) {
      this.userRights.roles = this.userRights.roles.filter(
        (role) => role !== roleId
      );
    },

    selectRoles(value) {
      if (!this.userRights.roles.includes(value)) {
        this.userRights.roles = [...this.userRights.roles, value];
      }
    },

    async loadCities(value) {
      await this.$store.dispatch("getZipCodeData", value);
      this.userAddress[0].zip = value;
      this.userAddress[0].city = this.zipCodeData[0].city;
    },

    async createUser() {
      this.isRequesting = true;
      if (this.isEdit) {
        this.image = this.editingUser.photo
          ? this.editingUser.photo.file_name
          : "";
      }
      const user = {
        ...this.userDetails,
        ...this.userContact,
        ...this.userRights,
        phone: this.userContact.phone,
        prefix: this.userContact.phonePrefix,
        file: this.file,
        user_addresses: this.userAddress,
        user_banks: this.userBanks,

        settings: {
          locale: this.languages.find(
            (language) => language.id === this.userDetails.language
          ).code,
        },
      };
      try {
        if (this.isEdit || this.isProfileEdit) {
          let userResponse = null;
          if (this.$route.name === "AccountDetails" && user.file) {
            await this.$store.dispatch("addUserPhoto", {
              userId: this.userToEdit,
              file: user.file,
            });
          }
          if (this.userRights.password) {
            userResponse = await this.$store.dispatch("updateUser", {
              user: { ...user, confirmPassword: this.confirmPassword },
              userId: this.userToEdit,
            });
          } else {
            if(this.isProfileEdit){
              userResponse = await this.$store.dispatch("updateProfile", {
                user
              });
            }else {
              userResponse = await this.$store.dispatch("updateUser", {
                user,
                userId: this.userToEdit,
              });
            }

          }
          this.$store.commit("setUser", userResponse);
          if (this.$route.name === "Reports") {
            await this.$store.dispatch("getReportForms");
          }
        } else {
          await this.$store.dispatch("createUser", user);
          this.$store.commit("setToast", {
            display: true,
            type: "success",
            message: "User added successfully.",
          });
        }
        this.closeModal();
      } catch (err) {
        console.log(err);
        const { errors } = err.response.data;
        this.specificErrors = this.parseFieldErrors(errors);
        this.isRequesting = false;
      }
    },

    uploadImage(event) {
      this.file = event.target.files[0];
      this.userImage = URL.createObjectURL(this.file);
    },
  },
};
</script>
<style lang="scss" scoped>
.details {
  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      color: var(--text-2);
      font-family: var(--muli-text-semi-bold);
      font-size: var(--x-small);
    }
  }
}


</style>