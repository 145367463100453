<template>
  <div class="report">
    <div class="page__header">
      <div class="page__title">report</div>
      <div class="page__right"></div>
    </div>

    <div class="report__container">
      <div class="report__left">
        <div class="box box--default report__user-info">
          <div class="label label--gradient">User info</div>
          <Loading v-if="chooseDetailsLoading" />
          <template v-else>
            <div
              class="report__user-info-icon icon icon__edit"
              @click="editUser()"
            ></div>
            <div class="report__user-info-avatar">
              <img
                v-if="selectedAgentData.photo"
                :src="selectedAgentData.photo"
                alt=""
              />
              <div
                v-else
                class="initials"
                :style="{ background: selectedAgentData.color || '#7f8fa4' }"
              >
                {{
                  parseInitials(
                    selectedAgentData.name.split(" ")[0],
                    selectedAgentData.name.split(" ")[1]
                  )
                }}
              </div>
            </div>
            <div class="report__user-info-roles">
              <div
                class="report__user-info-role"
                v-for="(role, index) in selectedAgentData.roles"
                :key="role.id"
              >
                {{ role.title }}
                <span v-if="index !== selectedAgentData.roles.length - 1"
                  >,</span
                >
              </div>
            </div>
            <div class="report__user-info-name">
              {{ selectedAgentData.name }}
            </div>
          </template>
        </div>

        <div class="box box--default report__choose-details">
          <div class="label label--gradient">Choose Details</div>
          <Loading v-if="chooseDetailsLoading" />
          <div class="report__choose-details-body" v-else>
            <div class="form__field">
              <VSelect
                class="select select--default"
                :class="{ 'select--error': userIdHasError }"
                :options="agents"
                :reduce="(agent) => agent.id"
                placeholder="Agent"
                :getOptionLabel="
                  (agent) => `${agent.first_name} ${agent.last_name}`
                "
                v-model="userId"
              />
              <div class="form__error" v-if="userIdHasError">
                Please select an agent.
              </div>
            </div>
            <div class="form__field">
              <VSelect
                class="select select--default"
                :class="{ 'select--error': paidStatusHasError }"
                :options="statuses"
                :reduce="(status) => status.value"
                placeholder="Status"
                label="title"
                v-model="paidStatus"
              />
              <div class="form__error" v-if="paidStatusHasError">
                Please select a valid status.
              </div>
            </div>
            <div class="report__choose-details-row">
              <VSelect
                class="select select--default report__choose-details-month"
                :options="months"
                :reduce="(month) => month.value"
                placeholder="Month"
                label="title"
                v-model="month"
              />
              <VSelect
                class="select select--default report__choose-details-year"
                :options="years"
                placeholder="Year"
                v-model="year"
              />
            </div>

            <div class="report__choose-details-title">Period</div>

            <div class="form__field">
              <div
                class="report__choose-details-datepickers"
                :class="{
                  'report__choose-details-datepickers--error': periodHasError,
                }"
              >
                <Datepicker
                  class="input input--default"
                  placeholder="Start Date"
                  :config="statics.datePickerConfig"
                  @input="setStartDate($event)"
                  :value="startDate"
                />
                <div
                  class="input input--default report__choose-details-seperator"
                >
                  -
                </div>
                <Datepicker
                  class="input input--default"
                  placeholder="End Date"
                  :config="statics.datePickerConfig"
                  @input="setEndDate($event)"
                  :value="endDate"
                />
              </div>
              <div class="form__error">{{ periodErrorPlaceholder }}</div>
            </div>

<!--            <div class="report__choose-details-checkboxes">
              <div class="report__choose-details-checkbox">
                <div class="report__choose-details-label">AHV/IV</div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox__checkbox"
                    id="ahv"
                    v-model="ahv"
                  />
                  <label class="checkbox__label" for="ahv"></label>
                </div>
              </div>
              <div class="report__choose-details-checkbox"></div>
              <div class="report__choose-details-checkbox">
                <div class="report__choose-details-label">BVG</div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox__checkbox"
                    id="bvg"
                    v-model="showBvg"
                  />
                  <label class="checkbox__label" for="bvg"></label>
                </div>
              </div>
              <div class="report__choose-details-checkbox" v-if="showBvg">
                <input
                  type="text"
                  class="report__choose-details-input"
                  v-model="bvg"
                />
                <div class="report__choose-details-label">CHF</div>
              </div>
            </div>-->

            <div class="report__choose-details-buttons">
              <button
                type="button"
                class="button button--default"
                :disabled="reportsLoading"
                @click="searchReports()"
              >
                Search
              </button>
              <button
                type="button"
                class="button button--default button--reset"
                @click="reset()"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="report__right">
        <div class="box box--default report__details">
          <div class="label label--gradient">Report Details</div>
          <Loading v-if="reportsLoading" />

          <template v-else>
            <div
              class="report__details-header table__header box box--default"
              v-if="reports.length"
            >
              <div class="table__label table__label--medium">Date</div>
              <div class="table__label table__label--large">Customer</div>
              <div class="table__label table__label--large">Company</div>
              <div class="table__label table__label--medium">Ins. Type</div>
              <div class="table__label table__label--medium">Police Nr.</div>
              <div class="table__label table__label--medium">Agent</div>
              <div class="table__label table__label--medium">Type</div>
              <!-- <div class="table__label table__label--medium">Storno</div> -->
              <div class="table__label table__label--medium">Balance</div> 
              <div
                class="table__label table__label--medium table__label--last"
              ></div>
            </div>

            <div class="report__details-body">
              <button
                v-if="!reports.length && $route.query.paidStatus === 'true'"
                type="button"
                class="report__details-add button button--default button--animated"
                @click="$store.commit('setShowAddTransactionModal', true)"
              >
                <div class="button__icon"></div>
              </button>
              <NoData :type="'reports'" v-if="!reports.length" />
              <div
                v-else
                class="report__details-reports"
                v-for="(report, index) in reports"
                :key="reports[index].period"
              >
                <div class="report__details-year">
                  {{
                    `${getMonthName(reports[index].period.split("-")[1])} ${
                      reports[index].period.split("-")[0]
                    }`
                  }}
                </div>

                <div class="report__details-reports-body">
                  <Report
                    v-for="r in reportTypes[index].policyReports"
                    :key="r.id"
                    :report="r"
                  />

                   

                  <div
                    class="report__details-manual"
                    v-if="reportTypes[index].manualReports.length"
                  >
                    <div class="report__details-manual-seperator"></div>
                    <div class="report__details-manual-header">
                      <div
                        class="report__details-manual-data report__details-manual-data--small"
                      >
                        Date
                      </div>
                      <div
                        class="report__details-manual-data report__details-manual-data--medium"
                      >
                        Agent
                      </div>
                      <div
                        class="report__details-manual-data report__details-manual-data--medium"
                      >
                        Type
                      </div>
                      <div
                        class="report__details-manual-data report__details-manual-data--large"
                      >
                        Beschreibung
                      </div>
                      <!-- <div
                        class="report__details-manual-data report__details-manual-data--small"
                      >
                        Storno
                      </div> -->
                      <div
                        class="report__details-manual-data report__details-manual-data--medium"
                      >
                        Balance
                      </div> 
                      <div
                          class="table__label table__label--medium table__label--last"
                      >

                      </div>
                    </div>
                    <Report
                      v-for="r in reportTypes[index].manualReports"
                      :key="r.key"
                      :report="r"
                    />
                  </div>

                  <div class="report__details-info">
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Abschlussprovisionen
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.commissions }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Courtagen
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.brokerFees }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Bonus
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.bonus }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Sonstiges
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.others }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Stornokonto
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.depositReturn }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Storno
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.storno }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Bruttoprovision
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.commission_total - report.meta.deposit }}
                        </div>
                      </div>
                    </div>

                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Stronoreserven
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.deposit_reserves_total + report.meta.deposit }}
                        </div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          AHV - Beiträge
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.AHV }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          BVG - Beitrag
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.BVG }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">Storno</div>
                        <div class="report__details-info-data">{{(-1)*report.meta.deposit}}</div>
                      </div>
                      <div class="report__details-info-column">
                        <div
                          class="report__details-info-label report__details-info-label--bold"
                        >
                          Nettoprovisionen
                        </div>
                        <div
                          class="report__details-info-data report__details-info-data--bold"
                        >
                          {{ report.meta.subtotal }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="report__details-info-row report__details-info-row--seperator"
                    >
                      <div class="report__details-seperator"></div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div
                          class="report__details-info-label report__details-info-label--bold"
                        >
                          Stronoreserven Total
                        </div>
                        <div
                          class="report__details-info-data report__details-info-data--bold"
                        >
                          {{ report.meta.deposit_reserves_total }}
                        </div>
                      </div>
                      <div class="report__details-info-column">
                        <div class="report__details-info-label">
                          Finished Payout
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.finished_payout }}
                        </div>
                      </div>
                    </div>

                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div
                          class="report__details-info-label report__details-info-label"
                        >

                            Spessen


                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.expenses }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div
                          class="report__details-info-label report__details-info-label"
                        >

                           Spezielle Auslagen


                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.specialExpenses }}
                        </div>
                      </div>
                    </div>
                    <div class="report__details-info-row">
                      <div class="report__details-info-column">
                        <div class="report__details-info-label"></div>
                        <div class="report__details-info-data"></div>
                      </div>
                      <div class="report__details-info-column">
                        <div
                          class="report__details-info-label report__details-info-label--bold"
                        >
                          <button
                            v-if="canMakePayment(index)"
                            type="button"
                            class="button button--default button--pay"
                            @click="
                              openPayoutReviewModal(
                                report.meta,
                                reports[index].period
                              )
                            "
                          >
                            Auszahlung
                          </button>
                          <template v-else>Auszahlung</template>
                        </div>
                        <div class="report__details-info-data">
                          {{ report.meta.payout }}
                        </div>
                      </div>
                    </div>
                    <button
                      v-if="$route.query.paidStatus === 'true'"
                      type="button"
                      class="report__details-add button button--default button--animated"
                      @click="$store.commit('setShowAddTransactionModal', true)"
                    >
                      <div class="button__icon"></div>
                    </button>
                    <div class="report__details-info-print">
                      <div class="icon icon__print"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/partials/Loading";
import statics from "@/statics";
import Report from "@/components/Reports/Report";
import NoData from "@/partials/NoData";
import moment from "moment";
import { parseInitials } from "@/helpers/entityParsers";
export default {
  name: "Reports",

  components: {
    Loading,
    Report,
    NoData,
  },

  data() {
    return {
      statics,
      moment,
      parseInitials,
      statuses: [
        { title: "Booked", value: true },
        { title: "Open", value: false },
      ],
      months: [
        { title: "All", value: null },
        { title: "January", value: 1 },
        { title: "February", value: 2 },
        { title: "March", value: 3 },
        { title: "April", value: 4 },
        { title: "May", value: 5 },
        { title: "June", value: 6 },
        { title: "July", value: 7 },
        { title: "August", value: 8 },
        { title: "September", value: 9 },
        { title: "October", value: 10 },
        { title: "November", value: 11 },
        { title: "December", value: 12 },
      ],

      startYear: 2015,
      endYear: new Date().getFullYear(),
      chooseDetailsLoading: false,
      reportsLoading: false,
      showBvg: false,

      userId: "",
      paidStatus: "",
      month: "",
      year: "",
      startDate: "",
      endDate: "",
      ahv: true,
      bvg: "",

      userIdHasError: false,
      paidStatusHasError: false,
      periodHasError: false,
      periodErrorPlaceholder: "",
    };
  },

  watch: {
    userId() {
      this.validateUserId();
    },

    paidStatus() {
      this.validatePaidStatus();
    },

    startDate() {
      this.validatePeriod();
    },

    endDate() {
      this.validatePeriod();
    },
  },

  computed: {
    ...mapGetters(["reportForms", "reports"]),

    years() {
      return Array(this.endYear - this.startYear + 1)
        .fill()
        .map((_, index) => this.startYear + index)
        .reverse();
    },

    agents() {
      return this.reportForms.agents;
    },

    parsedReports() {
      return this.reports.map((report) => {
        let reports = [];
        for (let r in report) {
          if (r !== "meta" && r !== "period") {
            reports = [...reports, report[r]];
          }
        }
        return reports;
      });
    },

    reportTypes() {
      return this.parsedReports.map((report) => {
        let reportTypes = {
          manualReports: [],
          policyReports: [],
        };
        report.forEach((r) => {
          if (r.policy_service) {
            reportTypes.policyReports = [...reportTypes.policyReports, r];
          } else {
            reportTypes.manualReports = [...reportTypes.manualReports, r];
          }
        });
        return reportTypes;
      });
    },

    selectedAgentData() {
      let agent = this.agents.find((agent) => agent.id === this.userId);
      return {
        photo: agent.photo ? agent.photo.preview : null,
        name: `${agent.first_name} ${agent.last_name}`,
        roles: agent.roles,
        color: agent.color || null,
      };
    },
  },

  async created() {
    this.chooseDetailsLoading = true;
    this.reportsLoading = true;

    await this.$store.dispatch("getReportForms");

    if (
      Number(this.$route.query.userId) &&
      (this.$route.query.paidStatus === "true" ||
        this.$route.query.paidStatus === "false") &&
      ((this.$route.query.startDate && this.$route.query.endDate) ||
        this.$route.query.month ||
        this.$route.query.year)
    ) {
      this.userId = Number(this.$route.query.userId);
      this.paidStatus = this.$route.query.paidStatus === "true";
      this.month = this.$route.query.month
        ? Number(this.$route.query.month)
        : null;
      this.year = this.$route.query.year;
      this.startDate = this.$route.query.startDate;
      this.endDate = this.$route.query.endDate;
      this.ahv = this.$route.query.ahv === "true";
      this.showBvg = this.$route.query.bvg === "true";
      this.bvg = this.$route.query.bvgValue;
      if (
        !this.userIdHasError &&
        !this.paidStatusHasError &&
        !this.periodHasError
      ) {
        await this.$store.dispatch("getReports");
      }
    } else {
      this.setDefaultFilterValues();
    }

    this.chooseDetailsLoading = false;
    this.reportsLoading = false;
  },

  destroyed() {
    this.$store.commit("setReports", []);
  },

  methods: {
    canMakePayment(index) {
      const { manualReports, policyReports } = this.reportTypes[index];
      return [...manualReports, ...policyReports].every(
        (report) => report.type
      );
    },
    setStartDate(date) {
      this.startDate = date;
    },

    setEndDate(date) {
      this.endDate = date;
    },

    getMonthName(month) {
      return this.months.find((m) => m.value === Number(month)).title;
    },

    setDefaultFilterValues() {
      this.userId = this.agents[0].id;
      this.paidStatus = this.statuses[0].value;
      this.month = this.moment().month() + 1;
      this.year = this.moment().year();

      if (
        this.userId != this.$route.query.userId ||
        this.paidStatus != (this.$route.query.paidStatus === "true") ||
        this.month != this.$route.query.month ||
        this.year != this.$route.query.year
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            userId: this.userId,
            paidStatus: this.statuses[0].value,
            month: this.moment().month() + 1,
            year: this.moment().year(),
          },
        });
      }
    },

    validateUserId() {
      if (!this.userId) {
        this.userIdHasError = true;
      } else {
        this.userIdHasError = false;
      }
    },

    validatePaidStatus() {
      if (this.paidStatus !== true && this.paidStatus !== false) {
        this.paidStatusHasError = true;
      } else {
        this.paidStatusHasError = false;
      }
    },

    validatePeriod() {
      if (this.startDate && this.endDate) {
        if (
          this.moment(this.startDate, "YYYY-MM-DD").isAfter(
            this.moment(this.endDate, "YYYY-MM-DD")
          )
        ) {
          this.periodHasError = true;
          this.periodErrorPlaceholder =
            "End date must not be greater than start date.";
          return;
        } else {
          this.periodHasError = false;
          this.periodErrorPlaceholder = "";
        }
      } else if (this.startDate) {
        if (!this.endDate) {
          this.periodHasError = true;
          this.periodErrorPlaceholder = "Please select end date";
        }
      } else if (this.endDate) {
        if (!this.startDate) {
          this.periodHasError = true;
          this.periodErrorPlaceholder = "Please select start date";
        }
      } else {
        this.periodHasError = false;
        this.periodErrorPlaceholder = "";
      }
    },

    openPayoutReviewModal(metaData, period) {
      this.$store.commit("setPayoutReviewModalData", {
        ...metaData,
        period,
      });
      this.$store.commit("setShowPayoutReviewModal", true);
    },

    editUser() {
      this.$store.commit("setUserToEdit", this.userId);
      this.$store.commit("setIsEdit", true);
      this.$store.commit("setShowAddUserModal", true);
    },

    async searchReports() {
      try {
        this.validateUserId();
        this.validatePaidStatus();
        this.validatePeriod();
        if (
          !this.userIdHasError &&
          !this.paidStatusHasError &&
          !this.periodHasError
        ) {
          if (
            this.userId != this.$route.query.userId ||
            this.paidStatus != (this.$route.query.paidStatus === "true") ||
            this.month != this.$route.query.month ||
            this.year != this.$route.query.year ||
            this.ahv != (this.$route.query.ahv === "true") ||
            this.showBvg != (this.$route.query.bvg === "true") ||
            Number(this.bvg) != Number(this.$route.query.bvgValue) ||
            this.startDate != this.$route.query.startDate ||
            this.endDate != this.$route.query.endDate
          ) {
            this.reportsLoading = true;

            this.$router.replace({
              query: {
                userId: this.userId,
                paidStatus: this.paidStatus,
                month: this.month,
                year: this.year,
                startDate: this.startDate,
                endDate: this.endDate,

              },
            });
            await this.$store.dispatch("getReports");
            this.reportsLoading = false;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },

    reset() {
      this.setDefaultFilterValues();
      this.ahv = true;
      this.showBvg = false;
      this.bvg = "";
    },
  },
};
</script>

<style scoped lang="scss">
.report,
.report__left,
.report__right,
.report__details {
  height: 100%;
}

.report {
  &__container {
    display: flex;
    padding-top: 20px;
    height: calc(100% - 24px - 20px);
  }

  .label {
    padding: 12px 15px;
    z-index: 3;
  }

  &__left {
    width: 250px;
    margin-right: 20px;
  }

  &__right {
    width: calc(100% - 250px - 20px);
  }

  &__user-info {
    height: 220px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 2px solid var(--border-2);
      overflow: hidden;
    }

    &-role {
      margin: 5px 0;
      color: var(--text-5);
      font-size: var(--x-smaller);
      display: inline-block;

      span {
        margin: 0 5px 0 -2px;
      }
    }

    &-name {
      color: var(--text-9);
      font-size: var(--medium);
      font-family: var(--muli-text-light);
    }

    &-icon {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }

  &__choose-details {
    height: calc(100% - 220px - 40px);
    padding: 45px 15px 0 15px;

    &-body {
      height: 100%;
      overflow: auto;
    }

    &-row,
    &-datepickers,
    &-seperator {
      display: flex;
    }

    &-datepickers {
      background: var(--bg-3);
      border-radius: var(--radius-1);
      border: 1px solid transparent;

      &--error {
        background-color: var(--highlight-2);
        border-color: var(--border-9);
      }
    }

    &-month {
      flex: 1;
      margin-right: 15px;
    }

    &-year {
      width: 80px;
    }

    .select {
      margin-bottom: 20px;
    }

    &-checkboxes,
    &-checkbox {
      display: flex;
      justify-content: space-between;
    }

    &-checkboxes {
      flex-wrap: wrap;
      border-top: 1px solid var(--border-7);
      padding-top: 20px;
      margin-top: 20px;
    }

    &-checkbox {
      margin: 0 20px 20px 0;
      width: calc(50% - 20px);
      align-items: center;
    }

    &-label {
      color: var(--text-2);
    }

    &-seperator {
      width: 20px;
      border-radius: 0;
      align-items: center;
      justify-content: center;
    }

    &-input {
      width: 55px;
      height: 28px;
      background: var(--bg-16);
      border-radius: var(--radius-1);
      text-align: center;
      color: var(--text-2);
    }

    &-title {
      color: var(--text-5);
      margin-bottom: 10px;
    }

    &-title,
    &-label {
      font-family: var(--muli-text-semi-bold);
      font-size: var(--x-small);
    }

    &-buttons {
      padding: 0 45px;

      .button {
        width: 100%;

        &--reset {
          border: 1px solid var(--border-2);
          background: 0;
          color: var(--text-2);
          margin-top: 15px;
          box-shadow: none;

          &:hover {
            background-color: var(--bg-2);
            color: var(--text-1);
          }
        }
      }
    }
  }

  &__details {
    padding: 30px 0 10px 0;

    &-header {
      margin-bottom: 0;
      .table__label--last {
        max-width: 45px;
      }
    }

    &-body {
      height: calc(100% - 36px);
      overflow: auto;
    }

    &-add {
      position: absolute;
      top: 68px;
      left: 45px;
      width: 55px;
      height: 38px;
      transform: translateX(-50%);

      &:hover {
        width: 65px;
      }
    }

    &-year {
      color: var(--text-2);
      font-size: var(--medium);
      font-family: var(--muli-text-bold);
      margin: 15px 0 15px 10px;
    }

    &-info,
    &-info-row,
    &-info-column {
      display: flex;
    }

    &-info {
      padding: 30px 75px 10px 15px;
      margin-top: -30px;
      border-radius: var(--radius-1);
      flex-direction: column;
      align-items: flex-end;
      position: relative;

      &-label,
      &-data {
        color: var(--text-9);

        &--bold {
          font-family: var(--muli-text-bold);
        }
      }

      &-label {
        width: 70%;
      }

      &-data {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 30%;
        max-width: 100%;
      }

      &-row {
        width: 70%;

        &--seperator {
          width: calc(70% + 45px);
        }
      }

      &-column {
        width: calc(50% - 100px);
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        text-align: right;
        &:first-child {
          margin-right: 200px;
        }
      }

      &-print {
        right: 10px;
        bottom: 10px;
      }

      .button,
      &-print {
        position: absolute;
      }

      .button {
        top: 50%;
        left: 60px;
        margin: initial;
        transform: translate(-50%, -50%);

        &--pay {
          position: static;
          transform: initial;
          width: 116px;
          height: 30px;
        }
      }
    }

    &-seperator {
      height: 1px;
      width: 100%;
      background: var(--bg-18);
      margin: 5px 0;
    }

    &-reports {
      &-body {
        background: var(--bg-16);
        border-radius: var(--radius-1);
      }
    }

    ::v-deep.report,
    &-manual-header {
      display: flex;
      .table__label--last {
        max-width: 45px;
      }
    }

    ::v-deep.report,
    &-manual {
      &__data,
      &-data {
        border-right: 1px solid var(--border-12);
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 15px 10px;
        color: var(--text-9);

        &--small,
        &--last {
          flex: 1;
        }
        &--medium {
          flex: 2;
        }
        &--large {
          flex: 3;
        }
        &--last {
          max-width: 45px;
          height: initial;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }

    &-manual {
      &-seperator {
        width: calc(100% - 20px);
        margin: auto;
        height: 1px;
        background: var(--border-12);
      }

      &-header {
        padding: 5px 0;

        .report__details-manual-data {
          text-align: center;
          color: var(--text-2);
          font-size: var(--x-small);
          padding: 5px 10px;
        }
      }
    }
  }

  &__user-info,
  &__choose-details,
  &__details {
    position: relative;
    background: var(--bg-1);
  }
}
</style>
